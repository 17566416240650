<template>
    <div class="about_page">
        <div class="container">
            <h1 class="page-title">About</h1>
            <p class="about-text">
               MaxGate Software Limited is an Isle of Man registered company and we are dedicated to providing the best products and services to our customers. Our team comprises market leading professionals in all fields, including software developers, programmers and operators. At MaxGate Softwarewe understand the fast paced and challenging market that our customers must operate within but with our extensive industry experience, we know that we help all our customers by delivering the best software solutions.
            </p>
            <div class="about-img">
                <img src="../assets/imgs/About-pic.jpg">
            </div>
        </div>
         <WhyMaxgate></WhyMaxgate>   
    </div>
</template>

<script>

import WhyMaxgate from "@/components/WhyMaxgate";
export default {
    components:{
        WhyMaxgate
    }
}
</script>
<style lang="scss" scoped>
.about_page{
    background-color: #f8f6f6;
    .container{
        padding: 2.6rem 0 9.2rem 0;
        .about-text{
            margin: 1rem 0 2.6rem 0;
            padding: 0 6.5rem 0 7.25rem;
            text-align: left;
            color: #525151;
            font-family: "Arial", Sans-serif;
            font-size: .8rem;
            font-weight: 500;
            line-height: 1.5;
        }
        .about-img{
            max-width: 42.5rem;        
            margin: 0 auto;
        }
    }
}


/* 手機版本 */
@media(max-width: 560px) {
    .about_page{
        .container{
            padding:.75rem;
            .about-text{
                margin: 1rem 0 2.6rem 0;
                padding: 0;
            }
            
        }
    }    
}
  


</style>