<template>
    <div>
        <!-- banner -->
        <div class="swiper-container">
            
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img src="../../assets/imgs/home_2.jpg">
                </div>
            </div>
            <!-- 分頁 -->
            <!-- <div class="swiper-pagination"></div> -->
        </div>
        <!--games -->
        <div class="games-section">
            <h1 class="page-title">Games</h1>
            <div class="game-wrapper" :class="`game-wrapper${index}`" v-for="(item, key, index) in gameList" :key="index">
                <div class="game-title">{{item.title}}</div>
                <div class="game-icon">
                    <router-link class="icon" v-for="(game,index) in item.games" :key="index" :to="'/games/' + game.path">
                        <img :src="require(`../../assets/imgs/gameIcon/${game.img}`)">
                    </router-link>
                </div>
            </div>

        </div> 
    </div>
</template>

<script>

import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
    data(){
        return{

            gameList:{
                Express:{
                    title:"Express - Live Drawing Frequency 75 SEC.",
                    games:[
                        {path:"Gamma3_Express",     name:"Gamma3",img:"gamma_3_express.png"},
                        {path:"Penta5_Express",     name:"Penta5",img:"penta_5_express.png"},
                        {path:"Infinity8_Express",  name:"Infinity8",img:"infinity_8_express.png"},
                        {path:"Cannon20_Express",   name:"Cannon20",img:"cannon_20_express.png"},
                        {path:"Lucky5_Express",     name:"Lucky5",img:"lucky_5_express.png"},
                        {path:"Rolling10_Express",  name:"Rolling10",img:"rolling_10_express.png"},
                    ]
                },
                Regular:{
                    title:"Regular - Live Drawing Frequency 3 MIN.",
                    games:[
                        {path:"Gamma3_Regular",   name:"Gamma3",img:"gamma_3_regular.png"},
                        {path:"Penta5_Regular",   name:"Penta5",img:"penta_5_regular.png"},
                        {path:"Infinity8_Regular",name:"Infinity8",img:"infinity_8_regular.png"},
                        {path:"Cannon20_Regular", name:"Cannon20",img:"cannon_20_regular.png"},
                        {path:"Lucky5_Regular",   name:"Lucky5",img:"lucky_5_regular.png"},
                        {path:"Rolling10_Regular",name:"Rolling10",img:"rolling_10_regular.png"},
                    ]
                },
                Classic:{
                    title:"Classic - Live Drawing Frequency 5 MIN.",
                    games:[
                        {path:"Penta5_Classic", name:"Penta5",img:"penta_5_classic.png"},
                        {path:"Surfing10_Classic", name:"Surfing10",img:"surfing_10_classic.png"},
                    ]
                },
                Daily:{
                    title:"Daily - Live Drawing Frequency Daily.",
                    games:[
                        {path:"Lucky7_Daily", name:"Lucky7",img:"lucky_7_daily.png"},
                       
                    ]
                },
            }
           
        }
    },
    methods:{
        toogleMenu(item){
            this.selectType = item.type;
            item.isShow = !item.isShow 
        }
    },
    mounted(){
        //  new Swiper('.swiper-container', {
        //     autoplay: {
        //         delay: 3000,
        //         disableOnInteraction: false,   
        //     },
        //     loop: false, 
        //     pagination: {
        //         el: ".swiper-pagination"
        //     },
        // })
    },
}
</script>
<style lang="scss" scoped>
.games-section{
    padding:2.5rem 0 3.8rem 0;
    background-color: #f8f6f6;
    @media(max-width: 415px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem 0.8rem;
    }
    .game-wrapper{
        width: 1300px;
        display: flex;
        flex-direction: column;
        margin: 0 auto 40px auto;
        @media(max-width: 415px) {
            width:100%;
            margin: 0 auto 10px auto;
        }
        .game-title{
            align-items: center;
            font-size:1.1rem;
            margin-bottom:0.625rem;
            display: flex;
            @media(max-width: 415px) {
                margin-top:0.625rem;
                font-size: 0.9rem;
            }
            @media(max-width: 321px) {
                margin-top:0.625rem;
                font-size: 0.8rem;
            }
            &::before{
                content: " ";
                width: 10px;
                height: 55px;
                display: block;
                background: #ccc;
                border-radius: 10px;
                margin-right:10px ;
                @media(max-width: 415px) {
                    width: 6px;
                    height: 35px;
                }
            }
        }  
        .game-icon{
            display: flex;
            justify-content:flex-start;
            @media(max-width: 415px) {
                flex-wrap: wrap;
            }
            .icon{
                width: 200px;
                height: 200px;
                margin-left: 17px;
                @media(max-width: 415px) {
                    width: 30%;
                    height: auto;
                    margin-left: 10px;
                    margin-bottom: 0.5rem;
                }
                @media(max-width: 321px) {
                    margin-left: 8px;
                }
            }
            
        }  
    }
    .game-wrapper0>.game-title::before{
        background: rgba(149, 34, 35, 1);
    }
    .game-wrapper1>.game-title::before{
        background: rgba(2, 63, 14, 1);
    }
    .game-wrapper2>.game-title::before{
        background: rgba(40, 40, 40, 1);
    }
    .game-wrapper3>.game-title::before{
        background:rgb(248, 180, 0);
    }

}

</style>