<template>
    <div class="footer">
        <div class="footer-nav">
            <ul>
                <li><router-link to="/" tag="a">Home</router-link></li>
                <li><router-link to="/games" tag="a">Games</router-link></li>
                <li><router-link to="/about" tag="a">About</router-link></li>
                <li><router-link to="/responsible_gaming" tag="a">Responsible Gaming</router-link></li>
                <li><router-link to="/contact" tag="a">Contact</router-link></li>
                <li><router-link to="/privacy_policy" tag="a">Privacy Policy</router-link></li>
                <li><router-link to="/terms_and_conditions" tag="a">Terms and Conditions</router-link></li>
                <li><router-link to="/Certifications" tag="a">Certifications</router-link></li>
            </ul>
        </div>
        <div class="footer-logo">
            <div class="footer-logo logo1"></div>
            <div class="footer-logo logo2"></div>
            <div class="footer-logo logo3"></div>
        </div>
        <div class="footer-copyright">
            <p>©️ 2020 Max Gate Software Limited. All Rights Reserved.</p>
        </div>
        <CookieNotice></CookieNotice>
    </div>
</template>

<script>
import CookieNotice from '@/components/CookieNotice';
export default {
    components:{
        CookieNotice
    }
}
</script>
<style lang="scss" scoped>
.footer{
    .footer-nav{
        background-color:#113253;
        padding: .8rem;
        display: flex;
        justify-content: center;
        ul{
            display: flex;
            flex-wrap:wrap;
            li{
                a{
                    font-size: .75rem;
                    color: #fff;
                    &:after{
                        content: '|';
                        color: #ffffff;
                        font-size: .5rem;
                        padding: .4rem; 
                    }
                    &:hover{
                        color: #ffcc00;
                    }
                }
            }
            li:nth-last-child(1) a:after{
                content: ' ';
            } 
        }
    }

    .footer-logo{
        display:flex;
        height: 5rem;
        justify-content: center;

        .footer-logo{
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 10rem;
            height: 5rem;
        }
        .logo1{
            background-image: url('../assets/imgs/logoBanner1.png');
        }
        .logo2{
            background-image: url('../assets/imgs/logoBanner2.png');
        }
        .logo3{
            background-image: url('../assets/imgs/logoBanner3.jpg');
        }
    }

    .footer-copyright{
        text-align: center;
        font-size: .6rem;
        margin-bottom: 2rem;
    }

}



/* 手機版本 */
@media(max-width: 414px) {
    .footer{
        .footer-nav{
            ul{
                justify-content: center; 
            }
        } 

        .footer-logo{
            padding: 0 0.6rem;
            background-size: 85%;   
        }
        .footer-logo:nth-child(2) {
            margin: 0 13px; 
        }
        .footer-copyright{
            padding: 0 0.2rem;
           
        } 
    }
}


</style>