<template>
    <div class="herader">
        <div class="header-bar">
            <router-link to="/" tag="a" class="logo"><img src="../assets/imgs/logo.png"></router-link>
            <!-- pc選單 -->
            <div class="nav">
                <ul>
                    <li><router-link class="nav-item" to="/home" tag="a">Home</router-link></li>
                    <li><router-link class="nav-item" to="/games" tag="a">Games</router-link></li>
                    <li><router-link class="nav-item" to="/news" tag="a">News</router-link></li>
                    <li><router-link class="nav-item" to="/about" tag="a">About</router-link></li>
                    <li><router-link class="nav-item" to="/responsible_gaming" tag="a">Responsible Gaming</router-link></li>
                    <li><router-link class="nav-item" to="/contact" tag="a">Contact</router-link></li>
                </ul>
            </div>
            <!-- mobile  -->
            <div class="mobile-btn"  @click="isShowMenu =!isShowMenu"></div>
        </div>
         <!-- mobile 選單 -->
        <div class="mobile-menu" v-show="isShowMenu">
            <ul>
                <li @click="closeMenu"><router-link class="nav-item" to="/home" tag="a">Home</router-link></li>
                <li @click="closeMenu"><router-link class="nav-item" to="/games" tag="a">Games</router-link></li>
                <li @click="closeMenu"><router-link class="nav-item" to="/news" tag="a">News</router-link></li>
                <li @click="closeMenu"><router-link class="nav-item" to="/about" tag="a">About</router-link></li>
                <li @click="closeMenu"><router-link class="nav-item" to="/responsible_gaming" tag="a">Responsible Gaming</router-link></li>
                <li @click="closeMenu"><router-link class="nav-item" to="/contact" tag="a">Contact</router-link></li>
            </ul>
        </div>

 
        
    </div>
</template>
<script>
export default {

    data(){
        return{
            isShowMenu:false
        }
    },

    methods: {
        closeMenu(){
            this.isShowMenu = false
        },
   
    },
  
}

</script>

<style lang="scss" scoped>
.herader{
    width: 100%;
    background: #fff;
    .header-bar{
        position: relative;
        padding: .8rem;
        margin: 0 auto;
        max-width: 65rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width: 414px) {
            width: 100%;
            max-width: auto;
            padding: .3rem 0.6rem;
            border-bottom: 1px solid #ccc;
        }
        .logo{
            width: 10.5rem;
            height: 4.8rem;
            min-width: 8.15rem;
            @media(max-width: 414px) {
                width: 70px;
                min-width: auto;
                height: auto;
            }
        }
        /* pc選單 */
        .nav{
            ul{
                display: flex;
                flex-wrap:wrap;
                justify-content:flex-end;
                li{
                    padding: .75rem;
                    .nav-item{
                        font-weight: 800;
                        color: #ccc;
                        font-size: 1rem;
                        
                        &:hover{
                            color:#113253; 
                        }
                        &.router-link-active{
                            color: #113253;;
                        }
                    }    
                }
            }
            @media(max-width: 414px) {
               display: none;
            }
        }
        /* mobile選單 */
        .mobile-btn{
            display: none;
            background: url("../assets/imgs/menu_icon.png");
            width: 17px;
            height: 15px;
            @media(max-width: 414px) {
               display: block;
            }
      
        } 
    } 
    .mobile-menu{
        display: none;
        text-align: center;
        font-size: 14px;
        line-height: 16px;

        ul li a{
            display: block;
            color: #ccc;
            line-height: 40px;
            font-weight: bold; 
            &.router-link-active{
                color: #113253;;
            }
        }
        @media(max-width: 414px) {
            display: block;
        }
    }     
}

/* 手機版本 */
// @media(max-width: 560px) {
//     .herader{
//         .header-bar{
//             align-items: center;
//             flex-direction: row;
//             .nav{
//                 display: none;
//             }
//             .mobile-nav{
//                 display: block;
//             }
//         }
//     }      
// }

</style>