<template>
    <div class="wapper">
        <div class="content">
            <div class="headerImg">
                <img src="../../assets/imgs/newsDetail/u15190.png">
            </div>
            <div class="shadowBox">
                <div class="titleRow">
                    <div class="text">
                        MaxGate Software Limited has gained certification from gaming laboratories BMM Testlabs.
                    </div>
                    <div class="dateRow">
                        <div class="date">
                            12.10.2020
                        </div>
                    </div>
                </div>
                <div class="news_content">
                    <p class="text">
                        MaxGate Software Limited, the gaming provider for regulated igaming markets, has gained
                        certification for the United Kingdom Remote Gambling Market from Gaming Laboratories BMM
                        Testlabs.
                    </p>
                    <br />
                    <br />
                    <p class="text">
                        MaxGate Software Limited is an Isle of Man registered cIt's proud to have measured up against
                        best industry practices and standards and is looking forward to the new opportunities that this
                        certification afforded, both in terms of entering regulated jurisdictions, and in enhancing our
                        reputation as a world-class igaming provider.”
                    </p>
                </div>
                <div class="paginationBar">
                    <div class="btn-left" @click="goNewDetal(1)">
                    </div>
                    <div class="btn" @click="goReturn">
                        Return
                    </div>
                    <div class="btn-right" @click="goNewDetal(3)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from "swiper";
    import "swiper/css/swiper.min.css";

    export default {
        data() {
            return {
                pageList: [{}],
            }
        },
        methods: {
            goNewDetal(index) {
                this.$router.push({
                    name: 'NewsDetail' + index
                });
            },
            goReturn() {
                this.$router.push({
                    name: 'News'
                });
            }
        },
        mounted() {
            new Swiper('.swiper-container', {
                autoplay: false,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    bulletClass: "news-pagination-bullets",
                    bulletActiveClass: "news-pagination-bullets-active",
                    clickable: true,
                },
            })
        },
    }
</script>
<style lang="scss" scoped>
    .wapper {
        background-color: rgb(248, 246, 246);
        width: 100vw;
        align-items: center;
        display: flex;
        flex-direction: column;

        .content {
            width: 1108px;

            .headerImg {
                margin-top: 80px;
                opacity: 0.85;
            }

            .shadowBox {
                box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
                margin-bottom: 115px;

                .titleRow {
                    display: flex;
                    height: 80px;
                    align-items: center;
                    padding: 0px 37px;
                    justify-content: space-between;

                    .text {
                        font-size: 20px;
                        font-weight: 700;
                        text-align: left;
                        color: rgb(7, 7, 7);
                        font-weight: 500;
                    }

                    .dateRow {
                        .date {
                            width: 101px;
                            height: 36px;
                            border-radius: 5px;
                            background-color: rgb(24, 50, 81);
                            font-family: Arial;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: center;
                            color: rgb(255, 255, 255);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .news_content {
                    width: 1108;
                    margin: 0px auto;
                    background-color: white;
                    padding: 28px 37px;
                    min-height: 475px;

                    .text {
                        font-family: Arial;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 30px;
                        text-align: left;
                        color: rgb(94, 94, 94);
                    }
                }

                .paginationBar {
                    height: 80px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0px 37px;

                    .btn-left {
                        width: 10px;
                        height: 18px;
                        background-image: url("../../assets/imgs/newsDetail/arrow_left.png");
                        cursor: pointer;
                    }

                    .btn {
                        font-family: Arial;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgb(49, 182, 233);
                        cursor: pointer;
                    }

                    .btn-right {
                        width: 10px;
                        height: 18px;
                        background-image: url("../../assets/imgs/newsDetail/arrow_right.png");
                        cursor: pointer;
                    }
                }
            }
        }
    }

    /* 手機版本 */
    @media(max-width: 414px) {
        .wapper {
            .content {
                width: 100%;
                padding: 0px 10px;

                .headerImg {
                    margin: 15px 0px;
                }

                .shadowBox {
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
                    margin-bottom: 55px;

                    .titleRow {
                        padding: 0px 10px;
                        height: unset;
                        align-items: unset;
                        display: block;

                        .text {
                            padding: 0px 5px;
                            font-size: 16px;
                            font-family: 'HelveticaNeue-Bold', 'Helvetica Neue Bold', 'Helvetica Neue';

                            font-weight: bold;
                        }

                        .dateRow {
                            direction: rtl;
                            margin-top: 10px;

                            .date {
                                width: 83px;
                                height: 25px;
                                border-width: 0px;
                                background: inherit;
                                background-color: rgba(24, 50, 81, 1);
                                border: none;
                                border-radius: 5px;
                                box-shadow: none;
                                font-size: 14px;
                            }
                        }
                    }

                    .news_content {
                        padding: 15px 7px 100px 7px;
                        margin: 10px;
                        min-height: unset;

                        .text {
                            font-size: 14px;
                            line-height: 28px;
                        }
                    }

                    .paginationBar {
                        height: 60px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 37px;

                        .btn-left {
                            width: 10px;
                            height: 18px;
                            background-image: url("../../assets/imgs/newsDetail/arrow_left.png");
                            cursor: pointer;
                        }

                        .btn {
                            font-family: Arial;
                            font-size: 14px;
                            font-weight: 400;
                            color: rgb(49, 182, 233);
                            cursor: pointer;
                        }

                        .btn-right {
                            width: 10px;
                            height: 18px;
                            background-image: url("../../assets/imgs/newsDetail/arrow_right.png");
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
</style>