<template>
    <transition name="slide"> 
        <div class="cookie-notice-wrapper" v-show="isShowCookieNotice">
            <h6>Cookie Notice</h6> 
            <div class="cookie-notice">
                <div class="desc">Max Gate Software uses cookies to help improve your experience whilst visiting our site, help us fulfil our legal and regulatory obligations. Please read our<router-link to="/privacy_policy" tag="a"> Privacy Policy </router-link>for further information on how we use information obtained from cookies. By continuing to use our website you consent to our use of cookies in accordance with our Cookie Policy.</div>
                <div class="cookie-btn">
                    <button type="button" @click="isShowCookieNotice=false">Accept</button>
                </div>
            </div> 
        </div>
    </transition> 
</template>

<script>
export default {
    data(){
        return{
           isShowCookieNotice:false
        }
    },
    mounted(){
        this.isShowCookieNotice=true
    }
}
</script>
<style lang="scss" scoped>
.cookie-notice-wrapper{
    position: fixed;
    bottom: 0;
    padding: 1rem 2rem;
    background:#4e4e4e;
    color: #fff;
    font-size: .6rem;

    h6{
        font-weight: 700;
        text-align: center;
    }
    .cookie-notice{
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-wrap: wrap;
        .desc{
            width: 95%;
            line-height: .8rem;
            a{
                color: blue;
                text-decoration-line: underline;
            }
        }
        .cookie-btn{
            text-align: center;
            button{
                margin-top:.5rem ;
                cursor: pointer;
                color: #fff;
                text-shadow: 0 -1px 0 rgba(0,0,0,.25);
                background-color: #006dcc;
                background-image: linear-gradient(to bottom,#08c,#04c);
                border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
                box-shadow: 0 1px 0 rgba(255,255,255,.2) inset, 0 1px 2px rgba(0,0,0,.05);
                padding: .25rem .5rem;
            }
        }   
    }   
}

.slide-enter-active {
  transition: all 1s ease;
}

.slide-enter{
  transform: translateY(100px);
  opacity: 0;
}

</style>