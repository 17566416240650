import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios' 
import Paginate from 'vuejs-paginate'
import timeZone from '@/lib/timeZone'

Vue.component('paginate', Paginate)

// 引入css
import './assets/scss/main.scss'

Vue.config.productionTip = false
Vue.prototype.$axios = axios

// 時區轉換
Vue.filter('formatToUTCTime', function(timeString) {
  return timeZone.toUTCTime(timeString);
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
