import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Games from '../views/Games/Games.vue'
import About from '../views/About.vue'
import ResponsibleGaming from '../views/ResponsibleGaming.vue'
import Contact from '../views/Contact.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Certifications from '../views/Certifications.vue'
import News from '../views/News/News.vue'
import NewsDetail1 from '../views/News/NewsDetail1.vue'
import NewsDetail2 from '../views/News/NewsDetail2.vue'
import NewsDetail3 from '../views/News/NewsDetail3.vue'

Vue.use(VueRouter)

  const routes = [
    {
        path: '/', 
        redirect: '/home' 
    }, 
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/games',
        name: 'Games',
        component: Games
        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }, 
    {
        path: '/news',
        name: 'News',
        component: News
    },
    {
        path: '/news/newsDetail/1',
        name: 'NewsDetail1',
        component: NewsDetail1
    }, 
    {
        path: '/news/newsDetail/2',
        name: 'NewsDetail2',
        component: NewsDetail2
    }, 
    {
        path: '/news/newsDetail/3',
        name: 'NewsDetail3',
        component: NewsDetail3
    }, 
    {
        path: '/about',
        name: 'About',
        component: About 
    },
    {
        path: '/responsible_gaming',
        name: 'ResponsibleGaming',
        component: ResponsibleGaming
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact  
    },
    {
        path: '/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy 
    },
    {
        path: '/terms_and_conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    },
    {
        path: '/certifications',
        name: 'Certifications',
        component: Certifications
    },

    // gameDetail Express 
    {
        path: '/games/Gamma3_Express',
        name: 'Gamma3_Express',
        component: () => import('../views/Games/Gamma3_Express.vue')
    },
    {
        path: '/games/Penta5_Express',
        name: 'Penta5_Express',
        component: () => import('../views/Games/Penta5_Express.vue')
    },
    {
        path: '/games/Infinity8_Express',
        name: 'Infinity8_Express',
        component: () => import('../views/Games/Infinity8_Express.vue')
    },
    {
        path: '/games/Cannon20_Express',
        name: 'Cannon20_Express',
        component: () => import('../views/Games/Cannon20_Express.vue')
    },
    {
        path: '/games/Lucky5_Express',
        name: 'Lucky5_Express',
        component: () => import('../views/Games/Lucky5_Express.vue')
    },
    {
        path: '/games/Rolling10_Express',
        name: 'Rolling10_Express',
        component: () => import('../views/Games/Rolling10_Express.vue')
    },
    // gameDetail Regular 
    {
        path: '/games/Gamma3_Regular',
        name: 'Gamma3_Regular',
        component: () => import('../views/Games/Gamma3_Regular.vue')
    },
    {
        path: '/games/Penta5_Regular',
        name: 'Penta5_Regular',
        component: () => import('../views/Games/Penta5_Regular.vue')
    },
    {
        path: '/games/Infinity8_Regular',
        name: 'Infinity8_Regular',
        component: () => import('../views/Games/Infinity8_Regular.vue')
    },
    {
        path: '/games/Cannon20_Regular',
        name: 'Cannon20_Regular',
        component: () => import('../views/Games/Cannon20_Regular.vue')
    },
    {
        path: '/games/Lucky5_Regular',
        name: 'Lucky5_Regular',
        component: () => import('../views/Games/Lucky5_Regular.vue')
    },
    {
        path: '/games/Rolling10_Regular',
        name: 'Rolling10_Regular',
        component: () => import('../views/Games/Rolling10_Regular.vue')
    },
    // gameDetail Classic 
    {
        path: '/games/Penta5_Classic',
        name: 'Penta5_Classic',
        component: () => import('../views/Games/Penta5_Classic.vue')
    },
    {
        path: '/games/Surfing10_Classic',
        name: 'Surfing10_Classic',
        component: () => import('../views/Games/Surfing10_Classic.vue')
    },
    // gameDetail Daily
    {
        path: '/games/Lucky7_Daily',
        name: 'Lucky7_Daily',
        component: () => import('../views/Games/Lucky7_Daily.vue')
    },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
    window.scroll(0, 0)
    next();
})

export default router
