<template>
    <div>
        <!-- banner -->
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <picture class="swiper-slide">
                    <source srcset="../assets/imgs/h5_images/h5_home_1.jpg" media="(max-width: 414px)">
                    <img src="../assets/imgs/home_1.jpg" alt="My default image">
                </picture>

                <picture class="swiper-slide">
                    <source srcset="../assets/imgs/h5_images/h5_home_3.jpg" media="(max-width: 414px)">
                    <img src="../assets/imgs/home_3.jpg" alt="My default image">
                </picture>
            </div>
            <!-- 分頁 -->
            <div class="swiper-pagination"></div>
        </div>

        <!--about -->
        <div class="about-section">
            <div class="container">
                <h1 class="page-title">ABOUT</h1>
                <div class="info">
                    MaxGate Software Limited is an Isle of Man registered company and we are dedicated to providing the best products and services to our customers. Our team comprises market leading professionals in all fields, including software developers, programmers and operators. At MaxGate Softwarewe understand the fast paced and challenging market that our customers must operate within but with our extensive industry experience, we know that we help all our customers by delivering the best software solutions.
                </div>
            </div>    
        </div>

        <!--whyMaxgate -->
        <WhyMaxgate></WhyMaxgate>
    </div>
</template>

<script>
import WhyMaxgate from "@/components/WhyMaxgate";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
    components:{
        WhyMaxgate
    },
    mounted(){
    
        new Swiper('.swiper-container', {
            autoplay: {
                delay: 3000,
                disableOnInteraction: false, 
            },
            loop: true, 
            pagination: {
                el: ".swiper-pagination"
            },
        })
    }
    
}
</script>
<style lang="scss" scoped>
// @import '@/assets/scss/mixin.scss';
.about-section{
    padding: 2.5rem 0 3.8rem 0;
   
    .info{
        margin-top: 1.5rem;
        color: #7a7a7a;
        font-size: 0.7rem;
        font-weight: normal;
        line-height:1.6;
        text-align: left;
    }
}


/* 手機版本 */
@media(max-width: 560px) {

    .about-section{
        padding: 1rem 0;
       
        .info{
            padding: 1rem;  
            font-size: 0.7rem;
            margin-top: 0;
        }
    }      
}

</style>