<template>
    <div class="whyMaxgate-section">
        <div class="container">
            <h2>WHY MAXGATE</h2> 
            <div class="whyMaxgate-reason">
                <div class="reason-wraper">
                    <div class="title">Excellence</div>
                    <div class="desc">Our core team has over 10 years experience in the gaming industry and understands the specific requirements and trends of the market, enabling us to offer our customers excellent results. </div>
                </div>
                <div class="reason-wraper">
                    <div class="title">Flexibility</div>
                    <div class="desc">Our team is dedicated to  delivering a premium  user experience to all customers and users by providing tailor-made services and integrated software that precisely  meets  any operator’s’ requirements.</div>
                </div>
                <div class="reason-wraper">
                    <div class="title">Quality</div>
                    <div class="desc">MaxGate Software aimsto deliver the finest quality products and services to all our customers. At MaxGate Software we believe that “Quality” is the key to success.</div>
                </div>
            </div>    
        </div>  
    </div>
   
</template>

<script>


export default {
    
}
</script>
<style lang="scss" scoped>
.whyMaxgate-section{
    background: #f4f2f2;
    padding: 50px 0px 76px 0px;

    h2{
        color: #113253;
        font-family: "Arial", Sans-serif;
        font-weight: 900;
        font-size: 42px;
        text-align: center;
    }

    .whyMaxgate-reason{
        display: flex;
        justify-content: space-between;
        width: 100%;
        line-height: 1.6;
        padding-top: 50px;

        .reason-wraper{
            width: 30.333%;
            .title{
                color: #113253;
                font-size: 26px;
                font-weight: 600;
            }
            .desc{
                color: #525252;
                font-size: 16px;
            }

        }

    }
}

/* 手機版本 */
@media(max-width: 560px) {
    .whyMaxgate-section{
        padding: 1rem;

        h2{
            font-size: 1rem; 
        }

        .whyMaxgate-reason{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            line-height: 1.6;
            padding-top: 0px;

            .reason-wraper{
                margin-top: .5rem;
                text-align: center;
                width: 100%;
                .title{
                    font-size: 0.85rem;
                    margin-top: .5rem;
                }
                .desc{
                    text-align: left;
                    font-size: 0.7rem;
                }
            }

        }
    }
}
</style>