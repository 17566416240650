<template>
    <div class="wapper">
        <div class="content">
        <!-- banner -->
            <div class="title">
                Latest News
            </div>
            <!--TopDesk-->
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="page">
                            <div class="item-1 item">
                                <div class="news-content" :class="isMobile ? 'showInTime': ''">
                                    <div class="date">
                                        01.11.2020
                                    </div>
                                    <div class="news_title">                                    
                                        MaxGate Software Limited partners with the UK-WL.                                    
                                    </div>
                                    <div class="news_text">                                    
                                        <p>MaxGate Software Limited, a leading system and</p>
                                        <p>game application developer, has signed an</p>
                                        <p>agreement to provide UK-WL (https://www.uk-</p>
                                        <p>wl.co.uk/) of the UK with its comprehensive range</p>
                                        <p>of lottery games.</p>
                                    </div>
                                    <div class="read_more" @click="goNewDetal(1)">
                                        READ MORE
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="page">
                            <div class="item-2 item">
                                <div class="news-content" :class="isMobile ? 'showInTime': ''">
                                    <div class="date">
                                        12.10.2020
                                    </div>
                                    <div class="news_title">                                    
                                        MaxGate Software Limited has gained certification from gaming laboratories BMM Testlabs.                                    
                                    </div>
                                    <div class="news_text">                                    
                                        <p>MaxGate Software Limited, the gaming provider</p>
                                        <p>for regulated igaming markets, has gained</p>
                                        <p>certification for the United Kingdom Remote</p>
                                        <p>Gambling Market ...</p>
                                    </div>
                                    <div class="read_more" @click="goNewDetal(2)">
                                        READ MORE
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="page">
                            <div class="item-3 item">
                                <div class="news-content" :class="isMobile ? 'showInTime': ''">
                                    <div class="date">
                                        08.02.2020
                                    </div>
                                    <div class="news_title">                                    
                                        MaxGate Software Limited established and gained the UKGC Licence.                                   
                                    </div>
                                    <div class="news_text">                                    
                                        <p>MaxGate Software Limited is an Isle of Man</p>
                                        <p>registered company and we are dedicated to</p>
                                        <p>providing the best products and services to our</p>
                                        <p>customers.</p>
                                    </div>
                                    <div class="read_more" @click="goNewDetal(3)">
                                        READ MORE
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 分頁 -->
                <div class="swiper-pagination"></div>
            </div>
            <!--Mobile-->
            <!-- <div class="swiper-container-mobile" v-show="isMobile">                
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in pageList" :key="index">
                        <div class="page">
                            <div class="item-1 item">
                                <div class="news-content">
                                    <div class="date">
                                        05.11.2020
                                    </div>
                                    <div class="title">                                    
                                        MaxGate Software Limited has gained certification from gaming laboratories BMM Testlabs.                                    
                                    </div>
                                    <div class="text">                                    
                                        <p>MaxGate Software Limited, the gaming provider</p>
                                        <p>for regulated igaming markets, has gained</p>
                                        <p>certification for the United Kingdom Remote</p>
                                        <p>Gambling Market ...</p>
                                    </div>
                                    <div class="read_more" @click="goNewDetal">
                                        READ MORE
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>-->
        </div>
    </div>
</template>

<script>

import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
    data(){
        return{
            newSwipper: null,
            pageList: [{},{},{},{},{},{}],
            isMobile: this.checkWindowSizeIsMobile(),
        }
    },
    methods:{
        goNewDetal(index){
            this.$router.push({ path: 'news/newsDetail/' + index });
        },
        onResize() {
            this.isMobile = this.checkWindowSizeIsMobile();
            // location.reload();
        },
        checkWindowSizeIsMobile () {
            if(window.outerWidth <= 414) {
                return true;
            } else {
                return false;
            }
        },
        createSwipper() {
            //<!--TopDesk-->
            new Swiper('.swiper-container', {
                autoplay: false,
                loop: true, 
                slidesPerView: this.checkWindowSizeIsMobile() ? 1 : 3,
                slidesPerGroup: this.checkWindowSizeIsMobile() ? 1 : 3,
                pagination: {
                    el: ".swiper-pagination",
                    bulletClass: "news-pagination-bullets",
                    bulletActiveClass: "news-pagination-bullets-active",
                    clickable: true,
                },
            });
            //<!--Mobile-->
            // new Swiper('.swiper-wrapper-mobile', {
            //     autoplay: false,
            //     loop: true, 
            //     pagination: {
            //         el: ".swiper-pagination",
            //         bulletClass: "news-pagination-bullets",
            //         bulletActiveClass: "news-pagination-bullets-active",
            //         clickable: true,
            //     },
            // });
        },
    },
    mounted(){
        this.createSwipper();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
}
</script>
<style lang="scss" scoped>
.wapper{
    width: 100vw;
    height: 769px;
    background-color: rgb(0, 0, 0);
    display: flex;
    .swiper-pagination-bullets{
        //display: none;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-top: 65px;
        position: relative;
    }
    .content{
        width: 1110px;
        margin: 0px auto;
        .title{
            margin-top: 100px;
            width: 166px;
            background-color: transparent;
            font-family: arial;
            font-size: 30px;
            font-weight: 400;
            color: rgb(255, 255, 255);
        }
        .page{
            //display: flex;
            margin-top: 40px;
            //justify-content: space-between;
            .item{
                width: 357px;
                height: 388px;
                //background-image: url("../../assets/imgs/news/u35.png");
                display: flex;
                .logo{
                    width: 180px;
                    margin: auto;
                }
                .news-content:hover {
                    transition-duration: 0.25s;
                    opacity: 1;
                }
                
                .news-content{
                    transition-duration: 0.25s;
                    background-color: rgba(25%,25%,25%,0.85);
                    opacity: 0;
                    padding: 23px;
                    width: 100%;
                    .date{
                        font-size: 16px;
                        font-weight: 400;
                        color: rgb(49, 182, 233);
                        margin-top: 20px;
                        height: 10px;
                    }
                    .news_title{
                        color: white;
                        margin-top: 30px;
                        font-size: 16px;
                        font-weight: bold;
                        width: 100%;
                        line-height: 24px;
                    }
                    .news_text{
                        color:white;
                        margin-top: 30px;
                        font-size: 13px;
                        font-weight: 100;
                        width: 100%;
                        line-height: 20px;
                    }
                    .read_more{
                        font-size: 20px;
                        font-weight: 400;
                        color: rgb(49, 182, 233);
                        margin-top: 25px;
                        cursor: pointer;
                    }
                }
                .showInTime {
                    animation-name: showInTimeKeyframes;
                    animation-duration:0.25s;
                    animation-delay: 1s;
                    animation-fill-mode:forwards;
                    -webkit-animation-duration:0.25s;
                    -webkit-animation-delay:1s;
                    -webkit-animation-fill-mode:forwards;
                }
                @keyframes showInTimeKeyframes {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
            }
            .item-1{
                background-image: url('../../assets/imgs/news/u822.png');
            }
            .item-2{
                background-image: url('../../assets/imgs/news/u49.png');
            }
            .item-3{
                background-image: url('../../assets/imgs/news/u831.png');
            }
        }
    }
}     

/* 手機版本 */
@media(max-width: 560px) {
    .wapper{
        width: 100vw;
        height: 600px;
        background-color: rgb(0, 0, 0);
        display: flex;
        .swiper-pagination-bullets{
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            margin-top: 30px;
            position: relative;
        }
        .content{
            margin: 0px auto;
            width: 100%;
            .swiper-container{
                z-index: 0;
            }
            .title{
                margin: 8% 5% 0% 5%;
                font-size: 20px;
            }
            .page{
                display: flex;
                margin-top: 40px;
                justify-content: center;
                .item{
                    @media(max-width: 320px) {
                        width: 305px;
                        height: 368px;
                    }
                    .logo{
                        width: 180px;
                        margin: auto;
                    }
                    .news-content:hover {
                        transition-duration: 0.25s;
                        opacity: 1;
                    }
                    .news-content{
                        transition-duration: 0.25s;
                        background-color: rgba(25%,25%,25%,0.85);
                        opacity: 0;
                        padding: 23px;
                        width: 100%;
                        .date{
                            font-size: 16px;
                            font-weight: 400;
                            color: rgb(49, 182, 233);
                            margin-top: 20px;
                            height: 10px;
                        }
                        .news_title{
                            color: white;
                            margin-top: 30px;
                            font-size: 16px;
                            font-weight: bold;
                            width: 100%;
                            line-height: 24px;
                        }
                        .news_text{
                            color:white;
                            margin-top: 30px;
                            font-size: 13px;
                            font-weight: 100;
                            width: 100%;
                            line-height: 20px;
                            @media(max-width: 320px) {
                                font-size: 12px;
                            }
                        }
                    }
                }   
                .item-1{
                    background-image: url('../../assets/imgs/news/u822.png');
                }
                .item-2{
                    background-image: url('../../assets/imgs/news/u49.png');
                }
                .item-3{
                    background-image: url('../../assets/imgs/news/u831.png');
                }             
            }
        }
    }
}

</style>