import moment from 'moment-timezone'

// 輸出時區
export default {
    getTimeZone: function() {
        let tz = moment.tz.guess();
        return tz
    },
    fomatLocalTime: function(value) {
        //空值判斷
        if(value == undefined || value == null || value =="")
        {
            return "";
        }
        let timeString = new Date(value).toString();
        let timeData = timeString.split("GMT")[0];
        //後端回傳時間定義為GMT+8
        timeData = timeData + 'GMT+0800';
        let tz = moment.tz.guess();
        let localTime = moment(timeData).tz(tz).format('YYYY-MM-DD HH:mm:ss');
        return localTime
    },
    formatStartDate: function(value) {
        //時間歸零
        let timeString = new Date(moment(value).format('YYYY-MM-DD 00:00:00'));
        //後端回傳時間定義為GMT+8
        let ServerTime = moment(timeString).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
        //歸零點  
        return ServerTime;
    },
    formatEndDate: function(value) {
        //時間歸零 結束時間加一天
        let timeString = new Date(moment(value).add(+1, 'Days').format('YYYY-MM-DD 00:00:00'));
        //後端回傳時間定義為GMT+8 
        let ServerTime = moment(timeString).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
        //歸零點  
        return ServerTime;
    },
    getMomentJS: function() {
        return moment;
    },
    toUTCTime: function(value) {
        //空值判斷
        if(value == undefined || value == null || value =="")
        {
            return "";
        }
        let timeString = new Date(value.replace(' ', 'T')).toString();
        let timeData = timeString.split("GMT")[0];
        //後端回傳時間定義為GMT+8
        timeData = timeData + 'GMT+0800';
        let UTCtime = moment(timeData).tz('Europe/London').format('YYYY-MM-DD HH:mm:ss');
        return UTCtime;
    }
}