<template>
    <div class="content-page">
        <div class="container">
            <div class="left">
                <h2>Get in Touch</h2>
                <div class="touch-info">
                    <img src="../assets/imgs/icon_contact_maps.png">
                    <span>22A Castle Street, Douglas, IM1 2EZ</span>
                </div>
                <div class="touch-info">
                    <img src="../assets/imgs/icon_contact_mail.png">
                    <span>support@maxgatesoftware.com</span>
                </div>
                 <div class="touch-info">
                    <img src="../assets/imgs/icon_contact_mobile.png">
                    <span>0113 234 3055</span>
                </div>
            </div>
            <div class="right">
                <h2>Contact</h2>
                <p>Want to find out more ? Feel free to use contact our team on the address below , or you can send our team a message using the contact form.
                    <span style="display:block">We are waiting to hear from you.</span>
                </p>
                
                <div class="submit-wrapper">   
                    <input type="text" name="name" placeholder="Name" v-model="name" />
                    <input type="text" name="Website" placeholder="Website" v-model="website"/>
                    <textarea rows="4" placeholder="Message" v-model="message"></textarea>
                </div>
                <div class="right-bottom">
                    <a class="sendMail" :href="`mailto:support@maxgatesoftware.com?subject=${name}&body=${website} ${message}`"></a>
                    <div class="verification">
                       <Recaptcha></Recaptcha>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import Recaptcha from '@/components/Recaptcha';
export default{
    components:{
        Recaptcha
    },
    data(){
        return{
            name:'',
            website:'',
            message:""
        }
    }
}

</script>
<style lang="scss" scoped>
.content-page{
    background-color: #f8f6f6;
    padding: 3rem 0;
    .container{
        display: flex;
        position: relative;
        .left{

            background: url('../assets/imgs/img_contact_bg.png');
            background-size: cover;
            width: 22.65rem;
            height: 26.5rem;
            padding: 3rem 2rem;
            box-sizing: border-box;
            
            h2{
                font-weight: 600;
                color: #f4b977;
                font-family: "Arial", Sans-serif;
                font-size: 1.45rem;
                margin-bottom: 1.5rem;
            }
            .touch-info{
                padding-bottom: 1rem;

                img{
                    vertical-align: middle;
                    width: 1.3rem;
                    height: 1.3rem;
                    margin-right:.75rem;

                }
                span{
                    color: #fff;
                }
            }
        }
        .right{
            width: 50%;
            background: #fff;
            padding: 1rem 2.5rem;
            height: 21rem;
            margin-top: 3rem;
                width: 50%;

            h2{
                color: #113253;
                font-weight: 600;
                font-family: "Arial", Sans-serif;
                font-size: 1.45rem;
                margin-bottom: 1rem;
            }
            p{
                color: #a7a6a6;
                font-family: "Arial", Sans-serif;
                font-size: .7rem;
                line-height: 1.5;
                margin-bottom: .5rem;
            }
            .submit-wrapper{
                padding: 0px 1.8rem 0px 0px;
                input{
                    margin-bottom: .5rem;
                }
            }
            .right-bottom{
                margin-top: 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;                
                .sendMail{
                    display: block;
                    background: url('../assets/imgs/btn_contact.png');
                    min-height: 2rem;
                    width: 5.6rem;
                    &:active{
                        margin-bottom: .15rem;
                    } 
                }
                .verification{
                    width: 15rem;
                    height: 3.7rem;
                    margin-right: 1.8rem;
                }
            }
        }     
    }
}

/* 手機版本 */
@media(max-width: 560px) {
    .content-page{
        padding: 1.5rem 0;
        .container{
            flex-direction: column;
            width: 100%;
            .left{
                width: 100%;
                height: auto;
                padding: 3rem 1.5rem;
                .touch-info{
                    span{
                        color: #fff;
                        font-size: 0.8rem;
                    }
                }
            }
           
            .right{
                width: 100%;
                height: auto;
                margin-top: .5rem;
                padding: 1rem;
                h2{
                   text-align: center;
                }
                .submit-wrapper{
                    padding: 0;
                }
               
                .right-bottom{
                    flex-direction: column;
                    align-items:flex-start;                  
                    .verification{
                       margin-top: .5rem;
                    }
                }
            }     
        }
    }

}
  

</style>